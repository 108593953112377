import React from 'react';

import './footer.css';

const Footer = (props) =>
    <div>
        <br />
        <br />
    </div>;

export default Footer;