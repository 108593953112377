const ActionTypes = {
    EDITOR: {
        CREATE: 'EDITOR.CREATE',
        DROP_NEW_MODEL: 'EDITOR.DROP_NEW_MODEL',
        ADD_NEW_MODEL: 'EDITOR.ADD_NEW_MODEL',
        ELEMENT_RIGHT_CLICKED: 'EDITOR.ELEMENT_RIGHT_CLICKED',
        ELEMENT_DOUBLE_CLICKED: 'EDITOR.ELEMENT_DOUBLE_CLICKED',
        ELEMENT_DELETE: 'EDITOR.ELEMENT_DELETE',
        ELEMENT_CANCEL: 'EDITOR.ELEMENT_CANCEL',
        ELEMENT_SAVE: 'EDITOR.ELEMENT_SAVE',
        ELEMENT_LABEL_EDIT: 'EDITOR.ELEMENT_LABEL_EDIT',
        ELEMENT_VALUE_EDIT: 'EDITOR.ELEMENT_VALUE_EDIT',
        ELEMENT_CHANGE_X: 'EDITOR.ELEMENT_CHANGE_X',
        ELEMENT_CHANGE_Y: 'EDITOR.ELEMENT_CHANGE_Y',
        ELEMENT_CHANGE_WIDTH: 'EDITOR.ELEMENT_CHANGE_WIDTH',
        ELEMENT_CHANGE_HEIGHT: 'EDITOR.ELEMENT_CHANGE_HEIGHT',
        ELEMENT_CHANGE_SIZE: 'EDITOR.ELEMENT_CHANGE_SIZE',
        ELEMENT_CHANGE_FONTSIZE: 'EDITOR.ELEMENT_CHANGE_FONTSIZE',
        ELEMENT_CHANGE_PERSPECTIVE: 'EDITOR.ELEMENT_CHANGE_PERSPECTIVE',
        TOOL_ELEMENT_CLICKED: 'EDITOR.TOOL_ELEMENT_CLICKED',
        TOOL_ELEMENT_RELEASED: 'EDITOR.TOOL_ELEMENT_RELEASED',
        TOOL_TAB_SELECTED: 'EDITOR.TOOL_TAB_SELECTED',
        MENU_CLEAR_CLICKED: 'EDITOR.MENU_CLEAR_CLICKED',
        MENU_CLEAR_CONFIRMED: 'EDITOR.MENU_CLEAR_CONFIRMED',
        CELL_CLICKED: 'EDITOR.CELL_CLICKED',
        CELL_HANDLE_CLICKED: 'EDITOR.CELL_HANDLE_CLICKED',
        CELL_HANDLE_RELEASED: 'EDITOR.CELL_HANDLE_RELEASED',
        CELL_HANDLE_MOVED: 'EDITOR.CELL_HANDLE_MOVED',
        CLEAR_GRAPH: 'EDITOR.CLEAR_GRAPH',
        SET_GRAPH: 'EDITOR.SET_GRAPH',
        SET_CURR_GRAPH: 'EDITOR.SET_CURR_GRAPH',
        SET_PAPER: 'EDITOR.SET_PAPER',
        SET_CURR_SHAPES: 'EDITOR.SET_CURR_SHAPES',
        SET_CELL_RESIZING: 'EDITOR.SET_CELL_RESIZING',
        SET_ELEMENT_POSITION: 'EDITOR.SET_ELEMENT_POSITION',
        SET_MOVING_LINKS: 'EDITOR.SET_MOVING_LINKS',
        TOGGLE_INFO_BOX: 'EDITOR.TOGGLE_INFO_BOX',
        SET_EDITOR_POSITION: 'EDITOR.SET_EDITOR_POSITION',
        SET_MODAL_POSITION: 'EDITOR.SET_MODAL_POSITION',
        TOGGLE_INDICATORS: 'EDITOR.TOGGLE_INDICATORS',
        ELEMENT_CHANGE_INDICATOR_TYPE: 'EDITOR.ELEMENT_CHANGE_INDICATOR_TYPE'
    }
}

export default ActionTypes;
